<template lang="pug">
.navigation-wrap(:class="[ hasScrolledHalfView ? 'sticky' : '', exitSticky ? 'exit-sticky' : '']" )
	//- Desktop
	.navigation-desktop
		.container.section.nav-inner
			transition(name="fade-absolute-left")
				NuxtLink.flex-child(@click="scrollToTop")
					SingaLogo.singa-logo.start(:imgSrc="story.logo.filename" alt="singa logo")
			transition(name="fade-absolute-right")
				.flex-child
					.nav-right(v-if="isLoggedIn")
						SingaButton.is-regular.is-primary.is-rounded.upsell-btn(v-if="!hasPremiumSub" :href="story.get_premium_link.cached_url" :target="story.get_premium_link.target" tag="a") {{ story.get_premium_cta }}
						SingaDropdown.nav-menu.nav-user-dropdown(aria-role="list" :mobile-modal="false" position="bottom-left" itemActiveClass="")
							template(#trigger="{ active }")
								SingaButton.is-transparent-dark.is-regular(
									:aria-label="t('account.title')"
									icon-left="person"
									:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
							.account-info
								.user-details(v-if="user")
									span.singer-name {{ user?.name }}
									span.user-email(v-if="hasPremiumSub") {{ user?.email }}
									SingaButton.is-pill.is-rounded.is-primary.nav-dropdown-button(tag="a" v-else :href="story.freemium_songs_link.cached_url") {{ t('button.freeSongs', { count: numberOfFreeSongsLeft }) }}
							hr(v-if="user")
							.links
								template(v-for="link in story.links")
									SingaDropdownItem(v-if="link.component === 'StoryblokLink'" tag="div")
										StoryblokLink(:blok="link")
									.dropdown-item(v-else-if="link.component === 'StoryblokSongRequestModal'")
										StoryblokSongRequestModal.dropdown-item-clickable(:blok="link" tab-index="0")
								SingaDropdownItem(tag="div")
									SingaButton.is-full-ghost.is-text-cta.is-justify-content-flex-start.dropdown-item-clickable(@click="logout()") {{ t('general.logout') }}

					.nav-right(v-else)
						SingaButton.is-full-ghost.log-in(tag="a" :aria-label="story.cta_business" :href="story.cta_business_link") {{ story.cta_business }}
						SingaButton.is-full-ghost.log-in(tag="router-link" :aria-label="story.cta_log_in" :to="localePath('/login/')") {{ story.cta_log_in }}
						SingaButton.is-regular.is-primary.recolor(:to="localePath(story.create_account_link.cached_url)" :target="story.create_account_link.target" tag="router-link" :style="{'background-color': story.button_bgColor}") {{ story.create_account_cta}}

	//- Mobile
	.navigation-mobile
		.container.section
			.top
				.left
					NuxtLink.flex-child(:to="localePath('/')")
						SingaLogo.singa-logo.start(:imgSrc="story.logo.filename" alt="singa logo")
				.right
					SingaButton.is-white(@click="appModal()") {{ t('global.downloadApp') }}
					SingaButton(v-if="!isLoggedIn" icon-left="person" @click="loginModal()" :class="!hasScrolledHalfView ? '' : 'is-transparent-dark'")
					SingaDropdown.mobile-nav-menu(v-else aria-role="list" :mobile-modal="false" position="bottom-left")
						template(#trigger="{ active }")
							SingaButton.is-transparent-dark(
								size="small"
								:class="!hasScrolledHalfView ? 'lander-mobile' : ''",
								icon-left="person"
								:icon-right="active ? 'caret-up-outline' : 'caret-down-outline'")
						.user-wrap(v-if="user")
							.user-info
								span.user-name.ellipsis {{username}}
								//- span.user-limits {{ t('button.freeSongs', { count: numberOfFreeSongsLeft }) }}
						.links
							template(v-for="link in story.links")
								SingaDropdownItem(v-if="link.component === 'StoryblokLink'" tag="div")
									StoryblokLink(:blok="link")
								.dropdown-item(v-else-if="link.component === 'StoryblokSongRequestModal'")
									StoryblokSongRequestModal.dropdown-item-clickable.song-request(:blok="link" tab-index="0")

							SingaDropdownItem(aria-role="listitem" @click="logout()") {{ t('general.logout') }}
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useScroll } from '@vueuse/core'
import { useUserStore } from '../../pinia/userStore'

import AuthCard from '~/components/auth/AuthCard.vue'

const DownloadApp = resolveComponent('ModalsDownloadApp')

const { $oruga } = useNuxtApp()
const localePath = useLocalePath()
const auth = useAuth()
const { isLoggedIn } = auth
const userStore = useUserStore()
const { username, user, hasPremiumSub, numberOfFreeSongsLeft } = storeToRefs(userStore)
const { t } = useI18n()

defineProps({
	story: {
		type: Object,
		default: null
	}
})

const hasScrolledHalfView = ref(false)
const exitSticky = ref(false)

const el = window
const smooth = ref(true)
const behavior = computed(() => smooth.value ? 'smooth' : 'auto')
const { y } = useScroll(el, { behavior })
const { directions } = useScroll(el)
const { top: toTop } = toRefs(directions)

const handleScroll = () => {
	if (import.meta.server) { return }

	const exitHeight = ((window.innerHeight / 2) + 180)
	if (y.value >= (window.innerHeight / 2)) {
		hasScrolledHalfView.value = true
	} else {
		hasScrolledHalfView.value = false
	}
	// For scroll up
	if (toTop.value && y.value > window.innerHeight / 3 && y.value < exitHeight) {
		exitSticky.value = true
	} else {
		exitSticky.value = false
	}
}

const scrollToTop = () => {
	if (import.meta.server) { return }

	const route = useRoute()

	if (route.fullPath.includes('karaoke-near-me')) {
		smooth.value = false
		navigateTo(localePath('/'))
		y.value = 0
		return
	}
	y.value = 0
}

onMounted(() => {
	window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
	window.removeEventListener('scroll', handleScroll)
})

const loginModal = () => {
	$oruga.modal.open({
		component: AuthCard,
		width: 1088
	})
}

const appModal = () => {
	$oruga.modal.open({
		component: DownloadApp,
		scroll: 'keep'
	})
}

const logout = async () => {
	await auth.logout(true, t('auth.loggedOut'))
}
</script>

<style lang="sass" scoped>
.navigation-wrap
	z-index: 50
	position: absolute
	width: 100%

.sticky
	position: fixed
	top: 0
	background-color: $transparent-black-84
	backdrop-filter: blur(10px)
	filter: drop-shadow(5px 5px 4px $transparent-black-56)
	animation: slide-in 0.3s ease-out forwards

	@keyframes slide-in
		0%
			transform: translateY(-100%)
			opacity: 0
		100%
			transform: translateY(0)
			opacity: 1

.exit-sticky
	position: fixed
	top: 0
	background-color: $transparent-black-84
	backdrop-filter: blur(10px)
	filter: drop-shadow(5px 5px 4px $transparent-black-56)
	animation: slide-out 0.3s ease-in forwards

	@keyframes slide-out
		0%
			transform: translate(0)
			opacity: 1
		100%
			transform: translateY(-100%)
			opacity: 0

.navigation-desktop
	position: relative
	transition: background-color 300ms
	transition: height 150ms
	transition: max-width 500ms
	display: none
	@media (min-width: $tablet)
		display: flex

.nav-inner
	height: auto
	width: 100% !important

.navigation-desktop
	.nav-inner
		padding-top: $spacing-24
		padding-bottom: $spacing-24
		display: flex
		justify-content: flex-start

.flex-child
	flex: 1
	display: flex
	justify-content: center
	&:first-child > svg
		margin-right: auto
	&:last-child > div
		margin-left: auto
.premium-cta
	margin-right: $spacing-24
	padding: 12px 20.5px !important
.log-in
	margin-right: $spacing-16
	display: flex
	text-shadow: 0 2px 5px rgba(0,0,0,.4)
	&:hover
		color: $color-grey-30 !important
.user-button
	line-height: 1
	padding: 7px 10px 7px 10px
.user-img
	width: 33px
	height: 33px
	border-radius: $radius-round
	margin-right: 10px

.navigation-mobile
	.singa-logo
		height: 40px
		width: auto
	.top
		display: flex
		align-items: center
		justify-content: space-between
		padding-bottom: $spacing-8
		.left
			position: absolute
		.right
			width: 100%
			.is-white.button
				margin-right: 12px
				height: 36px
				@include fontSize(xxs)
		@media (min-width: $mobile-small)
			align-items: center
			.left
				position: unset
			.right
				width: unset
	.right
		display: flex
		align-items: center
		justify-content: flex-end
		>:not(:last-child)
			margin: $spacing-16 0
	.bottom
		margin-bottom: $spacing-16
	.mobile-nav-menu
		.button
			padding: $spacing-8
			height: 36px
			:deep(.icon)
				margin: 0
				height: 20px
				width: 20px
	@media (min-width: $mobile-small)
		padding-top: $spacing-32
		.right
			display: inline-block
			>:not(:last-child)
				margin: 0 12px 0 0
	@media (min-width: $tablet)
		display: none

.expanded
	max-width: 100%

.flex-child
	justify-content: flex-start

.user-wrap
	padding: 24px
	display: flex
	position: relative
	&:after
		content: ''
		height: 1px
		background-color: $transparent-white-8
		position: absolute
		bottom: 0
		width: calc(100% - 48px)
		left: 0
		right: 0
		margin: auto
	img
		width: 56px
		height: 56px
		border-radius: $radius-default
		margin-right: $spacing-16
	.user-info
		display: flex
		flex-direction: column
		.user-name
			@include font(basier, medium)
		.user-limits
			@include font(basier, medium)
			@include fontSize(xs)
			border-radius: $radius-round
			background-color: $primary
			color: black
			padding: 4px 12px
			margin-top: 4px

.account-info
	background-color: $color-grey-80
	display: flex
	padding: $spacing-8 $spacing-24 $spacing-24
	border-radius: $radius-default $radius-default 0 0

hr
	border: 1px solid $transparent-white-16
	width: calc(100% - 48px)
	margin: 0 auto $spacing-8

.user-details
	display: flex
	flex-direction: column
	padding-left: $spacing-16
	overflow: hidden

.small-img
	width: 56px
	height: 56px
	border-radius: $radius-default

.singer-name
	@include fontSize(m)
	@include font(basier, medium)
	display: inline-block
	text-overflow: ellipsis
	white-space: nowrap
	overflow: hidden

.user-email
	display: inline-block
	text-overflow: ellipsis
	overflow: hidden
	@include fontSize(xs)
	@include font(basier, regular)

.nav-right
	display: flex
	align-items: center

.lander
	background-color: white
	border: none
	color: $color-grey-90

.nav-user-dropdown
	:hover
		.dropdown-button-small.lander
			background-color: $color-grey-10

.lander-mobile
	background-color: white
	border: none
	color: $color-grey-90

.nav-menu
	.dropdown-trigger
			:deep(.icon)
				margin: 0
				height: 20px
				width: 20px

.navigation-desktop
	.nav-menu
		.dropdown-trigger
			.button
				padding: 13px $spacing-16
				:deep(.button-wrapper)
					gap: $spacing-8
.navigation-mobile
	.nav-menu
		.dropdown-trigger
			.button
				padding: $spacing-8
				height: 36px

.upsell-btn
	margin: 0 $spacing-16 0 $spacing-16
</style>
