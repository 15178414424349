<template lang="pug">
.wrap
	VitePwaManifest
	LayoutLanderNav(v-if="settings && settings.content" :story="settings.content")
	.page
		slot(keep-alive)
	LayoutSingaFooter(v-if="settings && settings.content" :story="settings.content")
</template>

<script setup lang="ts">
const { locale } = useI18n()
const { $preview } = useNuxtApp()
const version = $preview ? 'draft' : 'published'

// Fetch the settings
const { story: settings } = await useStoryblokFetch('settings', {
	version,
	resolve_links: 'url'
})
const setLang = (language: string) => {
	useHead({
		htmlAttrs: {
			lang: language
		}
	})
}

watch(locale, (value) => {
	setLang(value)
})
</script>

<style lang="sass" scoped>
.layout-canvas
	background: linear-gradient(#052E18, black)
	height: 100vh
	width: 100%
	position: absolute
	opacity: 0.1
html
	background-color: black
.wrap
	min-height: 100vh
	display: flex
	flex-direction: column
	background-size: cover
	background-position: center
.page
	flex: 1
	position: relative
	overflow: hidden
.footer
	margin-top: auto
.relative-wrapper
	position: relative
.fixed-bottom
	width: 100%
	position: fixed
	bottom: 0
	left: 0
	z-index: 60
	background: black
</style>
